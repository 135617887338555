body {
    background-color: #a1a1a1;
    font-family: 'Nunito', sans-serif;
}

.form-control[readonly]{
  background-color: #fff;
}

.resigned{
  background: red !important;
  color: #fff;
  text-decoration: line-through;
}

.login-form {
    width: 340px;
    margin: 50px auto;
}
.login-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}
.login-form h1 {
    margin: 0 0 15px;
}

.login-form button {
    margin-top: 15px;
}

.register-form {
    width: 340px;
    margin: 50px auto;
}
.register-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}
.register-form h1 {
    margin: 0 0 15px;
}

.register-form button {
    margin-top: 15px;
}

.register-form input {
    margin-left: 15px;
}

/* base logged layout */

body {
    overflow-x: hidden;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

/* end base logged layout */

/* dataTables */

.dataTable tr.not-read {
	background: #fff0c4 !important;
}

.dataTable > tbody > tr:hover > td {
	background-color: #CFF5FF;
	cursor: pointer;
}

.dataTable.noPointer > tbody > tr:hover > td {
	background-color: initial;
	cursor: initial;
}

.dataTable.noPointer > tbody > tr > td > .mark-show > .mark-modal,
.dataTable.noPointer > tbody > tr > td > .mark-edit > .mark-modal {
	padding: 15px 5px;
	border: 0;
	line-height: 0;
	color: #fff;
}

.dataTable.noPointer > tbody > tr > td > .mark-show > .mark-modal {
	background-color: #1cc88a;
}

.dataTable.noPointer > tbody > tr > td > .mark-edit > .mark-modal {
	background-color: #4e73df;
}

.dataTable > tbody > tr:nth-child(even) {background: #f9f9f9}
.dataTable > tbody > tr:nth-child(odd) {background: #ffffff}

.dataTable > thead > tr {
    background-color: #d7d7d7;
    color: #000;
}

.new-mark-form, .change-mark-form {
	display: none;
}

/* tables */

td.b-top-none {
	border-top: none;
}

.d-sm-flex.align-items-center.justify-content-between.mb-4 h1{
  font-size: 14px;
}

/* marks */

#newMark {
	display: none;
}

.mark-show, .mark-edit, .mark-description-show {
	display: inline-block;
}

/* select2 */

.form-group>.select2 {
	width: 100% !important;
}

.select2-selection.select2-selection--single {
	height: 38px !important;
	outline: none !important;
	border: 1px solid #d1d3e2 !important;
}

.select2-selection__rendered {
	line-height: 38px !important;
}

.select2-selection__arrow {
	height: 36px !important;
}

@media screen and (max-width: 980px) {
  .fc-toolbar.fc-footer-toolbar{
    display: flex;
    flex-direction: column;
  }
  .fc-toolbar.fc-footer-toolbar .fc-right{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .fc-toolbar.fc-footer-toolbar .fc-right button{
    margin: 5px 10px;
  }
  .card-body a.btn{
    margin-top: 5px;
  }
}


.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover{
  opacity: .3;
}



.form-steps .steps {
	margin-bottom: 33px;
}
.form-steps .steps ul {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    list-style: none;
    padding-left: 0;
}
.form-steps .steps li,
.form-steps .steps li.current {
	outline: none;
	-o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -webkit-outline: none;
    position: relative;
    padding-bottom: 3px;
    padding-right: 63px;
}
.form-steps .steps li:last-child {
	padding-right: 0;
}
.form-steps .steps li .current-info {
	display: none;
}
.form-steps .steps li::before {
	position: absolute;
	content: "";
	background: #e5e5e5;
	width: 168px;
	height: 2px;
	top: 25%;
  left: 40%;
}
.form-steps .steps li:last-child::before {
	content: none;
}

.form-steps .steps li a {
	text-decoration: none;
  cursor: text;
	outline: none;
	-o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -webkit-outline: none;
}
.form-steps .steps li a .title {
	text-align: center;
}
.form-steps .steps li a .title span {
	display: block;
	font-size: 12px;
}
.form-steps .steps li a .title .step-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background: #ccc;
    margin: 0 auto;
    position: relative;
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -webkit-outline: none;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    vertical-align: middle;
}
.form-steps .steps li a .step-icon i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.form-steps .steps li.complited .step-icon{
    background: #4e73df !important;
}

.form-steps .steps li.current a .step-icon,
.form-steps .steps li.current a:active .step-icon,
.form-steps .steps .done::before,
.form-steps .steps li.done a .step-icon,
.form-steps .steps li.done a:active .step-icon {
    background: #124abe;
}
.form-steps .steps li.current a .step-icon,
.form-steps .steps li.current a:active .step-icon {
	box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
}
.form-steps .steps li a .step-number {
	color: #666;
	font-weight: 400;
    padding: 17px 0 8px;
    font-size: 12px;
}
.form-steps .steps li a .step-text {
	color: #333;
	font-weight: 600;
	padding-bottom: 8px;
}
